<template>
  <div>
    <div class="domain-buttons mb-1">
      <b>{{ props.domain?.name }}</b>
      <hr>
      <div class="d-flex justify-content-between">
        <div>
          <b-button
            v-for="std,index in standards"
            :key="std.id"
            v-b-tooltip.hover="std.name"
            variant="outline-dark"
            class="mb-1 ml-1 me-1"
            :class="{
              'active btn-success': std.id === selectedStandard,
              'btn-warning': std.id === reaStandard
            }"
            @click="handleStandardChange(std.id)"
          >
            S{{ index }}
          </b-button>
        </div>
        <div>
          <b-button
            v-b-tooltip.hover="'Topics on higher resolution'"
            variant="outline-dark"
            class="mb-1 ml-1 me-1"
            @click="handleNextStandard('up')"
          >
            <feather-icon icon="ArrowUpIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover="'Topics on lower resolution'"
            variant="outline-dark"
            class="mb-1 ml-1 me-1"
            @click="handleNextStandard('down')"
          >
            <feather-icon icon="ArrowDownIcon" />
          </b-button>
        </div>
      </div>
    </div>

    <div v-if="selectedStandard"
         class="main-points border dark p-2"
    >
      <h3 class="text-center">
        {{ standards.find(i => i.id === selectedStandard)?.name }}
      </h3>
      <div
        v-for="point,index in mainPoints"
        :key="index"
        class="form-check mb-1 d-flex main-point-box"
      >
        <div>
          <b-form-checkbox
            v-model="selectedMainPoints"
            :value="point.value"
            class="form-check-input"
          /></div>
        <div class="d-flex">
          <WordWithPopover
            :text="point.text"
            :word-power-enabled="true"
          />
          <b-button v-if="point.asset"
                    variant="flat-success"
                    class="btn-icon"
                    @click="showMediaPreview(point)"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
          <b-button v-if="canEdit"
                    variant="flat-warning"
                    class="btn-icon edit-icon"
                    @click="pointToEdit = point; pointIndexToEdit = index + 1"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </div>
      </div>
    </div>
    <MediaPreview v-if="!!mediaAsset"
                  :assets="mediaAsset"
                  @close="mediaAsset = null"
    />
    <EditMainPoint
      v-if="!!pointToEdit && selectedStandard"
      :point="pointToEdit"
      :index="pointIndexToEdit"
      :standard-id="selectedStandard"
      @refetch="onReFetch"
      @close="pointToEdit = null; pointIndexToEdit = 0"
    />
    <NextStandard
      v-if="nextStandard"
      :standard-id="nextStandard.standardId"
      :level="nextStandard.level"
      @close="nextStandard = null"
    />
  </div>
</template>
<script setup>
import axios from 'axios'
import { BButton, BFormCheckbox } from 'bootstrap-vue'
import {
  ref, onMounted, watch, getCurrentInstance,
} from 'vue'
import { USER_TYPE_SUPER } from '@/const/userType'
import WordWithPopover from '@/views/common/templates/components/WordWithPopover.vue'
import EditMainPoint from './EditMainPoint.vue'
import MediaPreview from './MediaPreview.vue'
import NextStandard from './NextStandard.vue'

const standardEndPoint = '/api/v1/ai-demo/get-standards'

const props = defineProps({
  domain: {
    type: Object,
    default: () => null,
  },
  reaStandard: {
    type: Number,
    default: 0,
  },
  defaultStandard: {
    type: Number,
    default: 0,
  },
})
const root = getCurrentInstance().proxy.$root
const canEdit = root.AUTH_USER() && root.AUTH_USER().usertype === USER_TYPE_SUPER
const pointToEdit = ref(null)
const pointIndexToEdit = ref(0)
const mediaAsset = ref(null)
const selectedMainPoints = ref([])
const selectedStandard = ref(null)
const standards = ref([])
const nextStandard = ref(null)
const emits = defineEmits(['onMainPointChange', 'onStandardChange'])

// const mainPoints = computed(() => standards.value.find(i => i.id === selectedStandard.value)?.main_points || {})
const mainPoints = ref([])

const getStandards = async () => {
  if (!props.domain || !props.domain.id) {
    console.error('Domain is not defined or missing an id:', props.domain)
    return
  }
  const response = await axios.post(`${standardEndPoint}/${props.domain.id}`)
  standards.value = response.data
}

const handleStandardChange = std => {
  selectedStandard.value = std
  const mainPointObj = standards.value.find(i => i.id === std)?.main_points || {}
  mainPoints.value = [
    {
      text: mainPointObj.point_1,
      value: mainPointObj.point_1,
      asset: mainPointObj.assets_url_1 || null,
      extra_assets: root.PARSE_ONLY_JSON(mainPointObj.extra_assets_1) || [],
    },
    {
      text: mainPointObj.point_2,
      value: mainPointObj.point_2,
      asset: mainPointObj.assets_url_2 || null,
      extra_assets: root.PARSE_ONLY_JSON(mainPointObj.extra_assets_2) || [],
    },
    {
      text: mainPointObj.point_3,
      value: mainPointObj.point_3,
      asset: mainPointObj.assets_url_3 || null,
      extra_assets: root.PARSE_ONLY_JSON(mainPointObj.extra_assets_3) || [],
    },
  ]
  selectedMainPoints.value = mainPoints.value.map(i => i.value)
  emits('onStandardChange', std)
}

watch(selectedMainPoints, newValue => {
  emits('onMainPointChange', newValue)
})

watch(() => props.defaultStandard, newValue => {
  if (newValue) {
    handleStandardChange(newValue)
  }
})

const onReFetch = async () => {
  await getStandards()
  handleStandardChange(selectedStandard.value)
}

onMounted(async () => {
  await getStandards()
  if (props.defaultStandard) {
    handleStandardChange(props.defaultStandard)
  }
})

const handleNextStandard = level => {
  nextStandard.value = {
    standardId: selectedStandard.value,
    level,
  }
}

const showMediaPreview = point => {
  mediaAsset.value = [point.asset, ...point.extra_assets]
}

</script>
<style lang="scss">
.main-point-box {
  .edit-icon {
    display: none;
  }
}
.main-point-box:hover {
  .edit-icon {
    display: inline-block;
  }
}
</style>
