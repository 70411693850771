<template>
  <!-- Modal for previewing assets -->
  <b-modal v-model="showModal"
           size="xl"
           hide-footer
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <template #modal-title>
      Asset Preview
    </template>

    <div class="asset-preview">
      <!-- Main asset preview carousel -->
      <b-carousel
        v-model="currentIndex"
        :interval="0"
        :controls="assets.length > 1"
        indicators
        class="main-preview mb-3"
      >
        <b-carousel-slide v-for="(asset, index) in assets"
                          :key="index"
                          class="h-100 w-100"
        >
          <template #img>
            <div class="d-flex justify-content-center align-items-center h-100 w-100">
              <img v-if="isImage(asset)"
                   ref="img"
                   v-image-preview
                   :src="asset"
                   alt="Asset Preview"
                   class="img-fluid w-100 h-100 object-fit-contain"
                   style="max-height: 80vh; width: auto !important;"
              >
              <video v-else-if="isVideo(asset)"
                     :src="asset"
                     controls
                     class="img-fluid w-100 h-100 object-fit-contain"
              />
              <div v-else-if="isAudio(asset)"
                   class="text-center w-100 h-100 d-flex align-items-center justify-content-center"
              >
                <AudioPlayer
                  :src="asset"
                />
              </div>
              <div v-else-if="isYoutubeUrl(asset)"
                   class="text-center youtube-container w-100 h-100"
              >
                <iframe :src="formatYoutubeUrl(asset)"
                        allowfullscreen
                        class="w-100 h-100"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              </div>
              <div v-else-if="isDocument(asset)"
                   class="text-center w-100 h-100 d-flex flex-column align-items-center justify-content-center"
              >
                <feather-icon icon="FileIcon"
                              size="48"
                              class="mb-2"
                />
                <b-button variant="primary"
                          @click="downloadFile(asset)"
                >
                  <feather-icon icon="DownloadIcon"
                                class="mr-1"
                  />
                  Download File
                </b-button>
              </div>
              <div v-else-if="isLinkValid(asset)"
                   class="iframe-container w-100 h-100"
                   :style="{ minHeight: modalHeight }"
              >
                <iframe
                  :src="asset"
                  frameborder="0"
                  allowfullscreen
                  class="iframe-content w-100 h-100"
                />
              </div>
              <div v-else
                   class="text-danger w-100 h-100 d-flex align-items-center justify-content-center"
              >
                Not a Valid Asset
              </div>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>

      <!-- Asset thumbnails -->
      <div
        class="asset-thumbnails"
      >
        <div v-for="(asset, index) in assets"
             :key="index"
             class="thumbnail"
             :class="{ active: index === currentIndex }"
             @click="currentIndex = index"
        >
          <img v-if="isImage(asset)"
               :src="asset"
               alt="Thumbnail"
          >
          <div v-else
               class="thumbnail-placeholder"
               :class="{ active: index === currentIndex }"
          >
            <feather-icon :icon="getAssetIcon(asset)" />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import { validatorYoutubeUrlValidator } from '@/@core/utils/validations/validators'
import { BCarousel, BCarouselSlide, BButton } from 'bootstrap-vue'

export default {
  components: {
    AudioPlayer,
    BCarousel,
    BCarouselSlide,
    BButton,
  },
  props: {
    assets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      currentIndex: 0,
      modalHeight: '100vh',
    }
  },
  computed: {
    currentAsset() {
      return this.assets[this.currentIndex]
    },
  },
  watch: {
    assets: {
      handler() {
        this.showModal = true
        this.currentIndex = 0
      },
      immediate: true,
    },
  },
  methods: {
    isImage(url) {
      return url.match(/\.(jpeg|jpg|gif|png|bmp|svg)$/i)
    },
    isVideo(url) {
      return url.match(/\.(mp4|avi|mov|wmv|mkv)$/i)
    },
    isAudio(url) {
      return url.match(/\.(mp3|wav|ogg|flac|aac)$/i)
    },
    isDocument(url) {
      return url.match(/\.(pdf|doc|docx|xls|xlsx|csv|txt|rtf|ppt|pptx)$/i)
    },
    isYoutubeUrl(url) {
      return /^(https?:\/\/)?(www\.)?(youtube|youtu|vimeo)\.(com|be)\/(watch\?v=|embed\/)([a-zA-Z0-9_-]+)(&.*)?$/.test(url)
    },
    isLinkValid(url) {
      try {
        // eslint-disable-next-line no-new
        new URL(url)
        return true
      } catch (_) {
        return false
      }
    },
    formatYoutubeUrl(url) {
      return validatorYoutubeUrlValidator(url)
    },
    getAssetIcon(url) {
      if (this.isVideo(url)) return 'VideoIcon'
      if (this.isAudio(url)) return 'MusicIcon'
      if (this.isYoutubeUrl(url)) return 'YoutubeIcon'
      if (this.isDocument(url)) return 'FileIcon'
      return 'LinkIcon'
    },
    downloadFile(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<style scoped>
.asset-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-preview {
  width: 100%;
  max-height: 70vh;
  background-color: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.main-preview >>> .carousel-inner {
  min-height: 500px;
}

.main-preview >>> .carousel-indicators li {
  background-color: #7367f0;
}

.main-preview >>> .carousel-control-prev-icon,
.main-preview >>> .carousel-control-next-icon {
  background-color: rgba(115, 103, 240, 0.5);
  border-radius: 50%;
  padding: 1.5rem;
}

.asset-thumbnails {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 10px;
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #7367f0 #f8f8f8;
}

.asset-thumbnails::-webkit-scrollbar {
  height: 6px;
}

.asset-thumbnails::-webkit-scrollbar-track {
  background: #f8f8f8;
  border-radius: 3px;
}

.asset-thumbnails::-webkit-scrollbar-thumb {
  background-color: #7367f0;
  border-radius: 3px;
}

.thumbnail {
  min-width: 80px;
  height: 60px;
  border: 2px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: white;
}

.thumbnail.active {
  border-color: #7367f0;
  transform: scale(1.05);
  box-shadow: 0 2px 8px rgba(115, 103, 240, 0.2);
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-placeholder {
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.thumbnail-placeholder.active {
  background-color: #7367f0;
  color: white;
}

.youtube-container {
  width: calc(100vw - 100px);
  max-width: 1100px;
  min-height: 500px;
}

.youtube-container iframe {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 8px;
}
</style>
