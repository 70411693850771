<template>
  <b-modal :visible="true"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <template #modal-title>
      <h5>Main Point Editor</h5>
    </template>
    <b-form @submit.prevent="submitForm">
      <b-form-group label="Main Point"
                    label-for="main-point-input"
      >
        <b-form-textarea
          id="main-point-input"
          v-model="mainPoint"
          required
        />
      </b-form-group>
      <b-form-group label="Assets URL"
                    label-for="assets-url-input"
      >
        <b-form-select v-model="assetInputType"
                       class="mb-2"
        >
          <option value="url">
            URL Input
          </option>
          <option value="file">
            File Upload
          </option>
        </b-form-select>

        <b-form-input
          v-if="assetInputType === 'url'"
          id="assets-url-input"
          v-model="assetsUrl"
          placeholder="Enter asset URL"
          required
        />
        <b-form-file
          v-else
          id="assets-file-input"
          v-model="assetsFile"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          required
        />
      </b-form-group>

      <b-form-group label="Extra Assets">
        <div v-for="(asset, index) in extraAssets"
             :key="index"
             class="d-flex mb-2"
        >
          <b-form-select v-model="extraAssetTypes[index]"
                         class="mr-2"
                         style="width: 120px"
          >
            <option value="url">
              URL Input
            </option>
            <option value="file">
              File Upload
            </option>
          </b-form-select>

          <b-form-input
            v-if="extraAssetTypes[index] === 'url'"
            v-model="extraAssets[index]"
            placeholder="Enter asset URL"
            class="mr-2"
          />
          <b-form-file
            v-else
            v-model="extraAssetFiles[index]"
            placeholder="Choose a file..."
            class="mr-2"
          />
          <b-button
            variant="danger"
            size="sm"
            @click="removeExtraAsset(index)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
        <b-button
          variant="primary"
          size="sm"
          class="mt-2"
          @click="addExtraAsset"
        >
          <feather-icon icon="PlusIcon" /> Add Asset
        </b-button>
      </b-form-group>
    </b-form>
    <template v-if="standard?.standard_families.length > 0">
      <div
        class="mb-1"
      >
        <h5>Associate Standards</h5>
        <span
          v-for="(associate, index) in standard.standard_families"
          :key="index"
          class="border rounded shadow-sm"
          style="padding: 4px; margin-right: 5px;"
        >
          {{ `${associate.children.name} - ${associate.children.code} ,` }}
        </span>
      </div>
    </template>

    <associate-standard ref="associateStandardRef"
                        :grades="grades"
    />

    <template #modal-footer>
      <b-button variant="secondary"
                @click="$emit('close')"
      >
        Cancel
      </b-button>
      <b-button type="submit"
                variant="primary"
                :disabled="isProcessing"
                @click="submitForm"
      >
        <b-spinner v-show="isProcessing"
                   small
        /> Save
      </b-button>

    </template>
  </b-modal>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import {
  BModal, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BSpinner, BFormSelect, BFormFile,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import AssociateStandard from '@/views/super/standard/components/standard/AssociateStandard.vue'

const props = defineProps({
  point: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    default: 0,
  },
  standardId: {
    type: Number,
    required: true,
  },
})

const standard = ref(null)
const associateStandardRef = ref(null)
const isProcessing = ref(false)
const grades = ref([])
const root = getCurrentInstance().proxy.$root
const emit = defineEmits(['close', 'refetch'])

const mainPoint = ref(props.point.text)
const assetInputType = ref('url')
const assetsUrl = ref(props.point.asset)
const assetsFile = ref(null)
const extraAssets = ref(props.point.extra_assets || [])
const extraAssetTypes = ref(Array(extraAssets.value.length).fill('url'))
const extraAssetFiles = ref(Array(extraAssets.value.length).fill(null))

const addExtraAsset = () => {
  extraAssets.value.push('')
  extraAssetTypes.value.push('url')
  extraAssetFiles.value.push(null)
}

const removeExtraAsset = index => {
  extraAssets.value.splice(index, 1)
  extraAssetTypes.value.splice(index, 1)
  extraAssetFiles.value.splice(index, 1)
}

const submitForm = () => {
  isProcessing.value = true

  // Here you would need to handle file uploads before making the API call
  // You may want to use FormData to send files to the server

  const formData = new FormData()
  formData.append('point', mainPoint.value)
  formData.append('index', props.index)
  formData.append('associate_standard', JSON.stringify(associateStandardRef.value.getSelectedStandards()))

  if (assetInputType.value === 'url') {
    formData.append('asset', assetsUrl.value)
  } else if (assetsFile.value) {
    formData.append('asset', assetsFile.value)
  }

  extraAssets.value.forEach((asset, index) => {
    if (extraAssetTypes.value[index] === 'url' && asset.trim() !== '') {
      formData.append(`extra_assets[${index}]`, asset)
    } else if (extraAssetFiles.value[index]) {
      formData.append(`extra_assets[${index}]`, extraAssetFiles.value[index])
    }
  })

  useJwt.updateStandardMainPoints(props.standardId, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(response => {
    root.showSuccessMessage(response)
    emit('refetch')
    emit('close')
  }).finally(() => {
    isProcessing.value = false
  }).catch(error => {
    root.showErrorMessage(error)
  })
}

onMounted(() => {
  useJwt.getStandardGrades({ per_page: 999 }).then(response => {
    grades.value = response.data.data.data
  })
  useJwt.showStandard(props.standardId, {
    params: {
      with_family: 1,
    },
  }).then(response => {
    standard.value = response.data.data
    console.log(standard.value.standard_families)
  })
})
</script>
