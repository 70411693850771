<template>
  <div class="header-area d-flex justify-content-between align-items-center px-2 py-1 border-bottom notranslate">
    <div class="d-flex align-items-center">
      <DarkToggler />
      <h5 class="mb-0 text-primary font-weight-bold">
        Advanced Research Ai Assistant
      </h5>
      <b-badge pill
               variant="light-primary"
               class="ml-2"
      >
        GEC
      </b-badge>
    </div>

    <div v-if="isLoggedIn"
         class="toolbar d-flex align-items-center"
    >
      <div :id="translateDiv" />
      <b-button-group>
        <b-button
          v-b-tooltip.hover
          variant="flat-primary"
          title="Chat History"
          class="btn-icon"
          @click="showHistory = true"
        >
          <feather-icon icon="ClockIcon"
                        size="14"
          />
        </b-button>
      </b-button-group>
    </div>
    <ReadHistory v-if="showHistory"
                 @close="showHistory = false"
    />
  </div>
</template>

<script setup>
import {
  BButtonGroup,
  BButton,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import {
  ref, getCurrentInstance, computed, onMounted,
} from 'vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import applyGoogleTranslate from '@/utils/googleTranslate'
import ReadHistory from './ReaHistory.vue'

const translateDiv = 'google_translate_element'
const showHistory = ref(false)
const root = getCurrentInstance().proxy.$root

onMounted(() => {
  applyGoogleTranslate(translateDiv)
})

const isLoggedIn = () => computed(() => !!root.AUTH_USER())
</script>

<style lang="scss" scoped>
.header-area {
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);

  .btn-icon {
    padding: 0.4rem;
    &:hover {
      background-color: rgba(115, 103, 240, 0.12);
    }
  }
}
</style>
